<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="green darken-2" max-height="65">
          <v-toolbar-title> {{ icsData.ICSCODE }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div>
          <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step
                v-if="total_req > 1"
                color="green"
                step="1"
                class="pa-3 pl-md-16"
                :complete="confirmed_req == total_req"
              >
              <span class="text-no-wrap">Requisition Approval</span>
              <small class="text-no-wrap font-size-12 font-weight-bold">Refer to Requestors List</small>
              </v-stepper-step>

              <v-stepper-step
                v-else
                color="green"
                step="1"
                class="pa-3 pl-md-16"
                :complete="confirmed_req == total_req"
                :rules="[() => pending_req == 0]"
              >
              <span class="text-no-wrap">Requisition Approval</span>
              <small class="text-no-wrap font-size-12 font-weight-bold">{{requestors.length > 0 ? formatDateTime(requestors[0].RequestedDate) : ''}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="icsData.ApprovedStatusID == 7"
                :rules="[() => icsData.ApprovedStatusID != 6]"
                step="2"
                class="pa-3"
              >
                <span class="text-no-wrap">President Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(icsData.ApprovedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="icsData.IssuedStatusID == 7"
                :rules="[() => icsData.IssuedStatusID != 6]"
                step="3"
                class="pa-3 pr-md-16"
              >
                <span class="text-no-wrap">Issuance Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(icsData.IssuedDate)}}</small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>
        <div class="mt-1" v-if="total_req > 1">
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey lighten-2">
                  <v-row no-gutters>
                    <v-col cols="4">
                      List of Requestors
                    </v-col>
                    <v-col
                      cols="8"
                      class="text--secondary"
                    >
                      <v-fade-transition leave-absolute>
                        <v-row no-gutters>
                          <v-col><span>Confirmed: <b>{{confirmed_req + '/' + total_req}}</b></span></v-col>
                          <v-col><span>Pending: <b>{{pending_req}}</b></span></v-col>
                        </v-row>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="headers_requestors"
                  :items="requestors"
                  :items-per-page="5"
                  class="mx-0"
                >
                  <template v-slot:[`item.FullName`]="{ item }">
                    <span class="text-no-wrap">{{ item.FirstName + " " + item.LastName }}</span>
                  </template>
                  <template v-slot:[`item.RequestedStatusID`]="{ item }">
                    <v-chip class="ma-2" v-if="item.RequestedStatusID == null">No Response</v-chip>
                    <v-chip class="ma-2" color="red" text-color="white" v-if="item.RequestedStatusID == 6">Pending</v-chip>
                    <v-chip class="ma-2" color="green" text-color="white" v-if="item.RequestedStatusID == 7">Approved</v-chip>
                  </template><template v-slot:[`item.RequestedRemarks`]="{ item }">
                    {{ item.RequestedRemarks || "---" }}
                  </template>
                  <template v-slot:[`item.RequestedDate`]="{ item }">
                    {{ formatDateTime(item.RequestedDate) || "Not yet responded" }}
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <v-alert
          border="left"
          type="error"
          v-if="(requestors.length == 1 && requestors[0].RequestedStatusID == 6 && total_req == 1) || icsData.ApprovedStatusID == 6 || icsData.IssuedStatusID == 6"
          prominent
          dense
          class="white--text amber accent-4"
        >
          <div v-if="requestors[0].RequestedRemarks"> <strong>Requestor:</strong> {{ requestors[0].RequestedRemarks }}</div>
          <div v-if="icsData.ApprovedRemarks"> <strong>President:</strong> {{ icsData.ApprovedRemarks }}</div>
          <div v-if="icsData.IssuedRemarks"> <strong>Issuer:</strong> {{ icsData.IssuedRemarks }}</div>
        </v-alert>
        <v-alert
          border="left"
          type="error"
          v-if="icsData.IsCancelled"
          prominent
          dense
          class="white--text amber accent-4"
        >
          <strong>CANCELLED</strong><br />
          <small>Date Cancelled: {{formatDateTime(icsData.DateCancelled)}}</small>
        </v-alert>
        <v-card-text style="max-height: 900px">
          <div>
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-h6"
                  >INVENTORY CUSTODIAN SLIP</v-subheader
                >
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-5">
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Entity Name:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>Davao del Norte State College</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Fund Cluster:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ icsData.FundCode }} - {{ icsData.SpecificFundSourceDescription }}</v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Supplier:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ icsData.CompanyName }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">IAR No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ icsData.IARCODE }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">PO No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ icsData.POCode }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Date Created:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ formatDate(icsData.DateCreated) }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">RIS No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ icsData.RISCODE }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Receiver:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ icsData.receiver }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">ICS No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ icsData.ICSCODE }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">ICS Purpose:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{icsData.ICSPurpose}}</v-subheader>
            </v-col>
          </v-row>
          </div>
          <v-row>
            <v-col lg="12" class="">
              <v-data-table
                v-model="formData.Lists"
                :headers="headers"
                :items="itemList"
                item-key="ICSItemID"
                class="elevation-1 mt-4"
                :expanded.sync="expanded1"
                :show-expand="!icsData.All"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >List Of Items</span
                    >
                    <v-spacer></v-spacer>
                    <span v-if="icsData.IsCancelled == 0">
                    <v-btn
                      color="header darken-2"
                      dark
                      outlined
                      elevation="2"
                      small
                      class="mr-2"
                      @click="approveICS(icsData)"
                      v-if="status == 0 || status == 1"
                    >
                      Approve
                    </v-btn>
                    <v-btn
                      color="red darken-2"
                      dark
                      outlined
                      elevation="2"
                      small
                      class="mr-2"
                      @click="pendingICS(icsData)"
                      v-if="status == 0"
                    >
                      Pending
                    </v-btn>
                    <v-btn
                      outlined
                      color="grey darken-2 mr-2"
                      dark
                      small
                      @click="edit()"
                      v-if="($store.state.user.roleID == 6 || $store.state.user.roleID == 16 || $store.state.user.roleID == 17) && (icsData.IssuedStatusID != 7 || icsData.MRTypeID == 3 || icsData.MRTypeID == 2) && icsData.IsCancelled == 0 "
                      class="mr-2"
                    >
                      <v-icon>mdi-pencil-outline</v-icon>
                      Edit ICS
                    </v-btn>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      small
                      @click="print(icsData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print
                    </v-btn>
                    <v-btn
                      color="red darken-2"
                      class="white--text"
                      small
                      v-if="($store.state.user.roleID == 6 || $store.state.user.roleID == 16 || $store.state.user.roleID == 17) && itemList.length > 0 && icsData.RSexist == 0"
                      @click="unpublish()"
                    >
                      Unpublish
                    </v-btn>
                    </span>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  <span class="text-no-wrap">{{ formatQty(item.Qty) }}</span>
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                </template>
                <template v-slot:[`item.EUL`]="{ item }">
                  <span class="text-no-wrap">{{ formatDate(item.EUL) }}</span>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="my-5">
                    <v-row v-for="(mr, index) in item.MRData" :key="index">
                      <v-col cols="12" class="px-2">
                        <v-row>
                          <v-col cols="12" sm="3">
                            <strong>Persons Accountable: </strong>
                          </v-col>
                          <v-col cols="12" sm="9">
                            {{getPANames(mr.UserID)}}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="4" class="px-2">
                         <strong>Brand: </strong> {{mr.Brand || 'Not applicable'}}
                      </v-col>
                      <v-col cols="12" sm="4" class="px-2">
                        <strong>Brand: </strong> {{mr.Model || 'Not applicable'}}
                      </v-col>
                      <v-col cols="12" sm="4" class="px-2">
                        <strong>Serial No.: </strong> {{mr.SerialNo || 'Not applicable'}}
                      </v-col>
                      <v-col v-if="item.MRData.length > index + 1 "><v-divider></v-divider></v-col>
                    </v-row>
                    </div>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="IPRDialog" width="1200" scrollable persistent>
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            <span>Dispense Items</span>
            <v-spacer></v-spacer>
            <v-btn color="white darken-2" icon text @click="closeDispense()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text style="max-height: 900px">
              <v-form @submit.prevent ref="IPRFormRef">
                <v-row class="mt-4">
                <v-col cols="12" v-if="itemList_ICS.length > 0">
                  <strong>ICS Details</strong>
                  <v-divider></v-divider>
                  <v-row no-gutters>
                    <v-col cols="12" md="6" v-if="icsData.MRTypeID == 3">
                      <v-text-field label="ICS Code" color="header" class="mx-3" v-model="formData.ICSCODE" :rules="[formRules.required]"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" v-if="icsData.MRTypeID == 3">
                      <v-menu
                        dense
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="formData.DateCreated"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            v-model="formData.DateCreated"
                            :rules="[formRules.required]"
                            label="Date Created"
                            readonly
                            class="mx-3"
                            v-bind="attrs"
                            v-on="on"
                            color="header"
                          >
                          </v-combobox>
                        </template>
                        <v-date-picker
                          v-model="formData.DateCreated"
                          no-title
                          scrollable
                          color="header"
                        >
                          <v-spacer></v-spacer>
                          <v-btn outlined color="grey" @click="menu1 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            dark
                            color="header"
                            @click="$refs.menu1.save(formData.DateCreated)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="6" v-if="icsData.MRTypeID == 3 && icsData.IARID == null">
                      <v-autocomplete
                      v-model="formData.FundID"
                      item-text="description"
                      item-value="id"
                      :rules="[formRules.required]"
                      :items="funds"
                      color="header" class="mx-3"
                      label="Fund Code">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" v-if="icsData.MRTypeID == 3 && icsData.IARID == null">
                      <v-autocomplete
                      v-model="formData.SpecificFundSourceID"
                      item-text="description"
                      item-value="id"
                      :rules="[formRules.required]"
                      :items="specificfundsources"
                      color="header" class="mx-3"
                      label="Specific Fund Source">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                      v-model="ICSDispenseType"
                      item-text="description"
                      item-value="id"
                      :rules="[formRules.required]"
                      :items="dispenseTypes"
                      color="header" class="mx-3"
                      label="Dispense Type (ICS)">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="ICS Purpose" color="header" class="mx-3" v-model="formData.ICSPurpose" :rules="[formRules.required]"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                  <v-col cols="12">
                <v-col cols="12">
              <!-- <v-toolbar flat dense class="my-0">
                <v-row align="center">
                    <v-divider></v-divider>
                    <v-switch
                    v-if="itemList_ICS.length > 1"
                    v-model="switchICS"
                    inset
                    class="ml-3"
                    color="header"
                    hide-details
                    label="Distribute/Share All"
                  ></v-switch>
                </v-row>
              </v-toolbar> -->
              <v-data-table
                :headers="headers2_ICS"
                :items="itemList_ICS"
                item-key="ICSItemID"
                class="elevation-1"
                :expanded.sync="expanded2"
                :show-expand="!switchICS"
              >
                <template v-slot:top>
                  <v-row no-gutters class="pa-5">
                    <v-col cols="12" md="6">
                    <span class="green--text text--darken-2 font-weight-bold"
                      >List Of Items (Inventory Custodian Slip)</span
                    >
                    </v-col>
                    <v-col cols="12" md="6">
                    <span v-if="itemList_ICS.length > 0">
                      <v-autocomplete
                           v-model="ICSAllUserID"
                            label="Person Accountable"
                            :rules="[formRules.required]"
                            dense
                            outlined
                            hide-details
                            v-if="ICSDispenseType == 1"
                            color="header"
                            :items="users"
                            item-text="name"
                            item-value="UserID"
                            type="text"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="ICSAllUserID"
                        label="Person Accountable"
                        :rules="[formRules.required, ICSAllUserID.length > 1]"
                        dense
                        outlined
                        v-if="ICSDispenseType == 2"
                        color="header"
                        :items="users"
                        hide-details
                        item-text="name"
                        item-value="UserID"
                        small-chips
                        deletable-chips
                        multiple
                      ></v-autocomplete>
                    <!-- <v-btn
                          color="green darken-2"
                          class="mr-2"
                          dark
                          elevation="2"
                          small
                          @click="distributeAll(item)"
                          v-if="ICSDispenseType == 1"
                        >
                          Distribute All
                        </v-btn>
                        <v-btn
                          color="blue darken-2"
                          class="mr-2"
                          dark
                          elevation="2"
                          small
                          @click="shareAll(item)"
                          v-if="ICSDispenseType == 2"
                        >
                          Share All
                        </v-btn> -->
                    </span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:[`item.StockPropertyNo`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.StockPropertyNo"
                    large
                  >
                    {{ item.StockPropertyNo }}
                    <template v-slot:input>
                      <v-textarea v-model="item.StockPropertyNo" v-if="item.IARItemID == null">
                      </v-textarea>
                      <span v-else style="color: red;margin-top: 10px;">Unable to edit the Stock/Property Number of this item.
                        <br />Please edit the IAR of this item instead.</span>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.ItemName`]="{ item }">
                <v-edit-dialog
                    :return-value.sync="item.ItemName"
                    large
                    v-if="item.IARItemID == null"
                  >
                    {{ item.ItemName }}
                    <template v-slot:input>
                      <v-textarea v-model="item.ItemName">
                      </v-textarea>
                    </template>
                  </v-edit-dialog>
                  <span v-else>{{item.ItemName}}</span>
                </template>
                <template v-slot:[`item.ItemDescription`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.ItemDescription"
                    large
                  >
                    {{ item.ItemDescription }}
                    <template v-slot:input>
                      <v-textarea v-model="item.ItemDescription">
                      </v-textarea>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.UnitMeasureDescription`]="{ item }">
                <v-edit-dialog
                    :return-value.sync="item.UnitMeasureID"
                    large
                    v-if="item.IARItemID == null"
                    @save="updateUnitMeasure(item)"
                  >
                    {{ item.UnitMeasureDescription }}
                    <template v-slot:input>
                      <v-autocomplete
                        v-model="item.UnitMeasureID"
                        label="Unit Measure"
                        :rules="[formRules.required]"
                        dense
                        outlined
                        color="header"
                        :items="unitmeasures"
                        item-text="UnitMeasureDescription"
                        item-value="UnitMeasureID"
                        class="mt-4"
                      ></v-autocomplete>
                    </template>
                  </v-edit-dialog>
                  <span v-else>{{item.UnitMeasureDescription}}</span>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.UnitCost"
                    large
                    v-if="item.IARItemID == null"
                  >
                    <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                    <template v-slot:input>
                      <v-text-field v-model="item.UnitCost">
                      </v-text-field>
                    </template>
                  </v-edit-dialog>
                  <span class="text-no-wrap" v-else>₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  {{ formatQty(item.Qty) }}
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty)) }}</span>
                </template>
                <template v-slot:[`item.OverallQty`]="{ item }">
                  {{ formatQty(item.OverallQty) || 0 }}
                </template>
                <template v-slot:[`item.AllowableQty`]="{ item }">
                  {{ formatQty(item.AllowableQty) || 0 }}
                </template>
                <template v-slot:[`item.EditableQty`]="{ item }">
                    <v-edit-dialog
                        large
                        :return-value.sync="item.EditableQty"
                        @save="updateMRItem(item)"
                    >
                    <span
                        :style="parseFloat(item.AllowableQty) < parseFloat(item.EditableQty) || parseFloat(item.EditableQty) <= 0 ? 'color:red' : 'color:green'">
                    {{ formatQty(item.EditableQty) || 0 }}
                    </span>
                    <template v-slot:input>
                    <v-text-field
                        v-model="item.EditableQty"
                        :rules="[parseFloat(item.AllowableQty) >= parseFloat(item.EditableQty), parseFloat(item.EditableQty) > 0]"
                        type="number"
                    >
                    </v-text-field>
                    </template>
                    </v-edit-dialog>
                </template>
                <template v-slot:[`item.EUL`]="{ item }">
                  <span class="text-no-wrap" v-if="icsData.RISID != null && icsData.IARID != null">{{ formatDate(item.EUL) }}</span>
                  <v-edit-dialog
                    :return-value.sync="item.EUL"
                    large
                    v-else
                  >
                    <span class="text-no-wrap">{{ formatDate(item.EUL) }}</span>
                    <template v-slot:input>
                      <v-menu
                        dense
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="item.EUL"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                        <v-date-picker
                          v-model="item.EUL"
                          no-title
                          scrollable
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-date-picker>
                        </template>
                      </v-menu>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" v-if="!switchICS">
                    <div class="mt-5">
                    <v-row no-gutters v-if="item.EditableQty > 5">
                        <v-col cols="12">
                          <v-alert
                            dense
                            type="info"
                            dark
                            dismissible
                            border="left"
                          >
                          You may input Brands, Models, and Serial No. in the designated fields below to fill in all fields efficiently.
                          </v-alert>
                        </v-col>
                        <v-col cols="12" md="4" class="px-2">
                          <v-textarea
                            v-model="item.MRData_str.Brand"
                            label="Enter Brands in Comma-Separated here..."
                            outlined
                            dense
                            rows="3"
                            color="header"
                            @keyup="fillBrands(item)"
                            :hint="hint_brand"
                            persistent-hint
                          >
                          </v-textarea>
                        </v-col>
                        <v-col cols="12" md="4" class="px-2">
                          <v-textarea
                            v-model="item.MRData_str.Model"
                            label="Enter Models in Comma-Separated here..."
                            outlined
                            dense
                            rows="3"
                            color="header"
                            @keyup="fillModels(item)"
                            :hint="hint_model"
                            persistent-hint
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="4" class="px-2">
                          <v-textarea
                            v-model="item.MRData_str.SerialNo"
                            label="Enter Serial No. in Comma-Separated here..."
                            outlined
                            dense
                            rows="3"
                            color="header"
                            @keyup="fillSerialNos(item)"
                            :hint="hint_serial_no"
                            persistent-hint
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-divider class="mb-6"></v-divider>
                    <v-row v-for="(mr, index) in item.MRData" :key="index" no-gutters>
                      <v-col cols="12" class="px-2">
                        <!-- <v-autocomplete
                           v-model="mr.UserID"
                            label="Person Accountable"
                            :rules="[formRules.required]"
                            dense
                            outlined
                            v-if="ICSDispenseType == 1"
                            color="header"
                            :items="users"
                            item-text="name"
                            item-value="UserID"
                            type="text"
                          ></v-autocomplete> -->
                          <!-- <v-autocomplete
                            v-model="mr.UserID"
                            label="Person Accountable"
                            :rules="[formRules.required]"
                            dense
                            outlined
                            v-if="ICSDispenseType == 2"
                            color="header"
                            :items="users"
                            item-text="name"
                            item-value="UserID"
                            small-chips
                            deletable-chips
                            multiple
                          ></v-autocomplete> -->
                      </v-col>
                      <v-col cols="12" md="4" class="px-2">
                        <v-text-field
                          v-model="mr.Brand"
                          label="Brand"
                          outlined
                          dense
                          color="header"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="px-2">
                        <v-text-field
                          v-model="mr.Model"
                          label="Model"
                          outlined
                          dense
                          color="header"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="px-2">
                        <v-text-field
                          v-model="mr.SerialNo"
                          label="Serial Number"
                          outlined
                          dense
                          color="header"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="px-2 mb-5" v-if="item.MRData.length > index + 1 "><v-divider></v-divider></v-col>
                    </v-row>
                    </div>
                  </td>
                </template>
              </v-data-table>
            </v-col>
                  </v-col>
                </v-row>
              </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" class="mr-1" outlined @click="closeDispense()">Cancel</v-btn>
              <v-btn color="green darken-2 white--text" @click="updateICS()" :loading="submitbtn"><v-icon>mdi-pencil-box-outline</v-icon>Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogConfirmSubmit" max-width="500px">
          <v-card>
            <v-card-title class="headline pt-5">Confirmation</v-card-title>
            <div class="mx-6">
              Are you sure you want to update this ICS?
            </div>
            <!-- <div class="mx-6 mt-5">
              <i><b>Note:</b> If the selected items have zero quantity used (distributed/shared), the item will be excluded automatically in the submission or creation of ICS, PAR, and RIS.</i>
            </div> -->
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogConfirmSubmit = false">Cancel</v-btn>
              <v-btn color="green darken-2 white--text" @click="updateICSConfirmed()"><v-icon>mdi-check-circle</v-icon> Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <v-dialog v-model="dialogUnpublish" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="subheader font-weight-bold">Confirmation</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="dialogUnpublish = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
          <v-card-text>
            Are you sure you want to unpublish this ICS?
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="dialogUnpublish = false">
          Cancel
          </v-btn>
          <v-btn
            color="red darken-3"
            :loading="isLoading"
            class="white--text"
            @click="confirmUnpublish()"
          >
          <v-icon>delete</v-icon>
            Unpublish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="approveDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to approve this Inventory Custodian Slip?
            <br /><br />
            <div>ICS #: <strong>{{approveData.ICSCODE}}</strong></div>
            <div>Purpose: <strong>{{approveData.ICSPurpose}}</strong></div>
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="approveDialog = false">Cancel</v-btn>
            <v-btn color="header darken-2 white--text" @click="confirmApproveICS()"><v-icon class="mr-1">mdi-check-circle</v-icon>Approve</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog v-model="pendingDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to pending this Inventory Custodian Slip?
            <br /><br />
            <div>ICS #: <strong>{{pendingData.ICSCODE}}</strong></div>
            <div>Purpose: <strong>{{pendingData.ICSPurpose}}</strong></div>
            <br />
            <v-form ref="formRef">
            <v-textarea
              color="green"
                dense
                v-model="formData.Remarks"
                :rules="[formRules.required]"
                label="Remarks"
                counter
                outlined
                maxlength="500"
                ></v-textarea>
            </v-form>
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="pendingDialog = false">Cancel</v-btn>
            <v-btn color="red darken-2 white--text" @click="confirmPendingICS()"><v-icon class="mr-1">mdi-clock-outline</v-icon>Pending</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  props: {
    icsData: {},
  },
  data: () => ({
    pardialog: false,
    dialog: false,
    users: [],
    dialogModel: false,
    PurposeDialog: false,
    supplier: [],
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    dialogIARVersion: false,
    link: "",
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Stock Number",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", align:"end", value: "UnitCost", sortable: false },
      { text: "QTY", align:"center", value: "Qty", sortable: false },
      { text: "Amount", align:"end", value: "Amount", sortable: false },
      { text: "Estimated Useful Life", align:"center", value: "EUL", sortable: false },
    ],
    expanded1: [],
    expanded2: [],
    singleExpand: true,
    formData: {
      Lists: [],
      ICSPurpose: '',
      ICSList: '',
      ICSCODE: null,
      DateCreated: null,
      FundID: null,
      SpecificFundSourceID: null,
    },
    itemList: [],
    itemList_filtered: [],
    tabs: null,
    headers2_ICS: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost", align:"center", sortable: false },
      { text: "Qty (Overall)", value: "OverallQty", align:"center", sortable: false },
      { text: "Qty (Allowable)", value: "AllowableQty", align:"center", sortable: false },
      { text: "Qty (Editable)", value: "EditableQty", align:"center", sortable: false },
      { text: "Estimated Useful Life", align:"center", value: "EUL", sortable: false },
    ],
    IPRDialog: false,
    itemList_ICS: [],
    submitbtn: false,
    dispenseTypes: [],
    ICSDispenseType: null,
    DSDialog: false,
    DSType: null,
    editDescription: false,
    dialogConfirmSubmit: false,
    generatedICSID : [],
    switchICS: false,
    ICSAllUserID: null,
    switchItemList: true,
    dialogUnpublish: false,
    isLoading: false,
    headers_requestors: [
          {
            text: 'Persons Accountable',
            align: 'start',
            value: 'FullName',
          },
          { text: 'Response', align: 'center', value: 'RequestedStatusID' },
          { text: 'Remarks', align: 'start', value: 'RequestedRemarks' },
          { text: 'Date Responded',  align: 'end', value: 'RequestedDate' },
        ],
        requestors: [],
    confirmed_req: 0,
    pending_req: 0,
    total_req: 0,
    hint_brand: '',
    hint_model: '',
    hint_serial_no: '',
    unitmeasures: [],
    funds: [],
    specificfundsources: [],
    approveDialog: false,
    pendingDialog: false,
    approveData: [],
    pendingData: [],
    status: null,
  }),
  watch: {
    icsData: {
      handler(data) {
        if (data.ICSID) {
          this.ICSDispenseType = data.DispenseTypeID;
          this.formData.ICSPurpose = data.ICSPurpose;
          this.formData.ICSCODE = data.ICSCODE;
          this.formData.DateCreated = data.DateCreated;
          if(data.IARID == null){
          this.formData.FundID = data.FundID;
          this.formData.SpecificFundSourceID = data.SpecificFundSourceID;
          }
          this.switchICS = data.All;
          // if(data.All == 1){
            if(data.DispenseTypeID == 1){
              this.ICSAllUserID = parseInt(data.UserID);
            }
            else if(data.DispenseTypeID == 2){
              let temp = data.UserID.split(",");
              this.ICSAllUserID = temp.map(str => {
                return parseInt(str);
              });
            }
          // }
          if(data.TransferID == null || data.MRTypeID == 2 || data.MRTypeID == 3){
            this.headers2_ICS = [
            {
              text: "Stock/ Property No.",
              align: "start",
              sortable: false,
              value: "StockPropertyNo",
            },
            {
              text: "Item Name",
              align: "start",
              sortable: false,
              value: "ItemName",
            },
            { text: "Description", value: "ItemDescription", sortable: false },
            {
              text: "Unit Measure",
              value: "UnitMeasureDescription",
              sortable: false,
            },
            { text: "Unit Cost", value: "UnitCost", align:"center", sortable: false },
            { text: "Qty (Overall)", value: "OverallQty", align:"center", sortable: false },
            { text: "Qty (Allowable)", value: "AllowableQty", align:"center", sortable: false },
            { text: "Qty (Editable)", value: "EditableQty", align:"center", sortable: false },
            { text: "Estimated Useful Life", align:"center", value: "EUL", sortable: false },
          ];
        }
        else{
          this.headers2_ICS = [
            {
              text: "Stock/ Property No.",
              align: "start",
              sortable: false,
              value: "StockPropertyNo",
            },
            {
              text: "Item Name",
              align: "start",
              sortable: false,
              value: "ItemName",
            },
            { text: "Description", value: "ItemDescription", sortable: false },
            {
              text: "Unit Measure",
              value: "UnitMeasureDescription",
              sortable: false,
            },
            { text: "Unit Cost", value: "UnitCost", align:"center", sortable: false },
            { text: "Qty (Overall)", value: "OverallQty", align:"center", sortable: false },
            { text: "Qty", value: "Qty", align:"center", sortable: false },
            { text: "Estimated Useful Life", align:"center", value: "EUL", sortable: false },
          ];
        }
        if(data.status_prop != null && typeof data.status_prop !== 'undefined'){
          this.status = data.status_prop;
        }
        this.getItems();
        this.getRequestor();
          this.dialogModel = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDispenseTypes();
    this.getUnitMeasures();
    this.getUtilities();
  },
  methods: {
    edit() {
      this.IPRDialog = true
    },
    print() {
      window.open(this.apiUrl + "/ics/report/" + this.icsData.ICSID);
    },
    closeDialog() {
      this.formData.Lists = [];
      this.itemList = [];
      this.dialogModel = false;
      this.eventHub.$emit("closeViewICS", false);
    },
    getItems() {
      this.unpublishBtn = false;
      this.formData.Lists = [];
      let data = new FormData();
      data.append("ICSID", this.icsData.ICSID);
      this.axiosCall("/ics/getItems", "POST", data).then((res) => {
        res.data.data.result.forEach(item => {
          item.AllowableQty = parseFloat(item.OverallQty) - (parseFloat(item.RenewQty) + parseFloat(item.TransferQty) + parseFloat(item.ReturnQty));
        });
        this.itemList = res.data.data.result;
        this.itemList_ICS = res.data.data.result;
        this.getMRData();
        this.users = res.data.data.users;
      });
    },
    getMRData(){
      this.itemList.forEach(item => {
        let data = new FormData();
        data.append("ICSItemID", item.ICSItemID);
        this.axiosCall("/mritem/ics/getItems", "POST", data).then((res) => {
          if(res.status){
            if(res.data.data.length > 0){
              res.data.data.forEach(item2 => {
                item2.UserID = item2.UserID.split(",").map(Number)
                if(this.ICSDispenseType == 1){
                item2.UserID = item2.UserID[0]
                }
              });
              item.MRData = res.data.data;
              item.MRData_str = res.data.data;
              item.MRData_temp = res.data.data;
            }
            else{
              let MRData = []
            for (let index = 0; index < item.Qty; index++) {
              MRData.push({
                Brand: null,
                Model: null,
                SerialNo: null,
                UserID: null
              });
            }
            item.MRData = MRData
            item.MRData_str = MRData
            item.MRData_temp = MRData
          }
          }
      });
      });
    },
    getPANames(data){
      if(data){
      let result = [];
      if(Array.isArray(data)){
        data.forEach(item1 => {
          this.users.forEach(item2 => {
            if(item1 == item2.UserID){
              result.push(item2.name)
            }
        });
        });
      }
      else{
        this.users.forEach(item => {
          if(data == item.UserID){
            result.push(item.name)
          }
        });
      }
      return result.join(', ')
      }
      else{
        return ''
      }
      
    },
    dispense() {
      this.IPRDialog = true;
    },
    closeDispense(){
      this.IPRDialog = false;
    },
    updateICS(){
      if(this.$refs.IPRFormRef.validate() && this.itemList_ICS.length > 0){
        this.dialogConfirmSubmit = true;
      }
      else{
        this.fadeAwayMessage.show = true;
        this.fadeAwayMessage.type = "error";
        this.fadeAwayMessage.header = "System Message";
        this.fadeAwayMessage.message = "Failed to continue. Please check the details.";
      }
    },
    getDispenseTypes() {
      this.axiosCall("/getAllDispenseType", "GET").then((res) => {
        this.dispenseTypes = res.data.data;
      });
    },
    updateICSConfirmed() {
      if(this.$refs.IPRFormRef.validate() && this.itemList_ICS.length > 0){
          let data = new FormData();
          data.append("ICSID", this.icsData.ICSID);
          data.append("ICSPurpose", this.formData.ICSPurpose);
          data.append("DispenseTypeID", this.ICSDispenseType);
          data.append("ICSCODE", this.formData.ICSCODE);
          data.append("DateCreated", this.formData.DateCreated);
          data.append("MRTypeID", this.icsData.MRTypeID);
          if(this.icsData.IARID == null){
          data.append("FundID", this.formData.FundID);
          data.append("SpecificFundSourceID", this.formData.SpecificFundSourceID);
          }
          data.append("UserID_all", this.ICSAllUserID);
          data.append("AllItem", this.switchICS ? 1 : 0);
          data.append("List", JSON.stringify(this.itemList_ICS));
          this.axiosCall("/ics/edit", "POST", data).then((res) => {
            if(res.data.status){
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "success";
                this.fadeAwayMessage.message = "ICS was successfully updated";
                this.fadeAwayMessage.header = "System Message";
                this.dialogConfirmSubmit = false;
                this.IPRDialog = false;
                this.getItems();
              }
              else{
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "error";
                this.fadeAwayMessage.message = "Something went wrong!";
                this.fadeAwayMessage.header = "System Message";
              }
            });
      }
    },
    unpublish(){
      this.dialogUnpublish = true;
    },
    confirmUnpublish(){
      let data = new FormData();
      data.append("ICSID", this.icsData.ICSID);
      this.axiosCall("/ics/unpublish", "POST", data).then((res) => {
        if(res.data.status){
          this.dialogUnpublish = false;
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "ICS was successfully unpublished";
          this.fadeAwayMessage.header = "System Message";
          this.dialogUnpublish = false;
          this.closeDialog();
        }
        else{
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = "Something went wrong!";
          this.fadeAwayMessage.header = "System Message";
        }
      });
    },
    getRequestor() {
      let data = new FormData();
      data.append("ICSID", this.icsData.ICSID);
      this.axiosCall("/getICSRequestors", "POST", data).then((res) => {
        this.requestors = res.data.data;
        let cnt = 0;
        let cnt2 = 0;
        res.data.data.forEach(item => {
          if(item.RequestedStatusID == 7){
            cnt++;
          }
          else if(item.RequestedStatusID == 6){
            cnt2++;
          }
        });
        this.confirmed_req = cnt;
        this.pending_req = cnt2;
        this.total_req = res.data.data.length;
      });
    },
    updateMRItem(item){
        item.MRData = item.MRData_temp
          let MRData = [];
            for (let index = 0; index < item.EditableQty; index++) {
              if(index >= item.MRData.length){
                MRData.push({
                Brand: null,
                Model: null,
                SerialNo: null,
                UserID: null
              });
              }
              else{
                MRData.push(item.MRData[index]);
              }
            }
          item.MRData = MRData;
          item.MRData_deleted = item.MRData_temp.splice(item.EditableQty, item.MRData_temp.length)
      this.switchICS = true
      this.switchICS = false
    },
    fillBrands(data){
      let brands = data.MRData_str.Brand.split(",");
      data.MRData.forEach((item, index) => {
        item.Brand = brands[index];
      });
      if(brands.length > 0){
        this.hint_brand = "No. of Entries: " + brands.length;
      }
    },
    fillModels(data){
      let models = data.MRData_str.Model.split(",");
      data.MRData.forEach((item, index) => {
        item.Model = models[index];
      });
      if(models.length > 0){
        this.hint_model = "No. of Entries: " + models.length;
      }
    },
    fillSerialNos(data){
      let serial_nos = data.MRData_str.SerialNo.split(",");
      data.MRData.forEach((item, index) => {
        item.SerialNo = serial_nos[index];
      });
      if(serial_nos.length > 0){
        this.hint_serial_no = "No. of Entries: " + serial_nos.length;
      }
    },
    getUnitMeasures() {
      this.axiosCall("/getCategory", "GET").then((res) => {
        this.unitmeasures = res.data.data.unitmeasure;
      });
    },
    updateUnitMeasure(data){
      this.unitmeasures.forEach(item => {
        if(item.UnitMeasureID == data.UnitMeasureID){
          data.UnitMeasureDescription = item.UnitMeasureDescription
        }
      });
    },
    getUtilities() {
      this.axiosCall("/csv/utilities", "GET").then((res) => {
        this.funds = res.data.data.funds;
        this.specificfundsources = res.data.data.sfs;
        // this.users = res.data.data.users;
        // this.dispensetypes = res.data.data.dispensetypes;
        // this.unitmeasures = res.data.data.unitmeasures;
      });
    },
    approveICS(data){
      this.approveData = data;
      this.approveDialog = true;
    },
    pendingICS(data){
      this.pendingData = data;
      this.pendingDialog = true;
    },
    confirmApproveICS(){
      let data = new FormData();
      data.append("ICSID", this.approveData.ICSID);
      this.axiosCall("/ics/approve", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "ICS was successfully approved.";
            this.fadeAwayMessage.header = "System Message";
            this.approveDialog = false;
            this.status = null;
            this.approveData = [];
            this.closeDialog()
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
    },
    confirmPendingICS(){
      if(this.$refs.formRef.validate()){
      let data = new FormData();
      data.append("ICSID", this.pendingData.ICSID);
      data.append("Remarks", this.formData.Remarks);
      this.axiosCall("/ics/pending", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "ICS was successfully in pending status.";
            this.fadeAwayMessage.header = "System Message";
            this.pendingDialog = false;
            this.status = 1
            this.pendingData = [];
            this.closeDialog()
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
      }
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>